import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import { TertiaryButton } from '../base/Buttons';

export const KeyConsiderationsHeader: React.FC<{
  onAdd: () => void;
}> = ({ onAdd }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h2">Key Considerations</Typography>
      <TertiaryButton disableRipple onClick={onAdd}>
        <AddIcon />
      </TertiaryButton>
    </Stack>
  );
};
