import {
  MandatoryFlags,
  MatterKeyConsideration,
  MatterKeyConsiderationCategory,
} from '../services/types/client-intake-types';

export const useKeyConsiderations = (
  keyConsiderations: MatterKeyConsideration[] = [],
) => {
  // Create a dictionary from key_considerations
  const keyConsiderationsMap: Record<
    MatterKeyConsiderationCategory,
    MatterKeyConsideration
  > = keyConsiderations.reduce(
    (acc, consideration) => {
      acc[consideration.category] = consideration;
      return acc;
    },
    {} as Record<MatterKeyConsiderationCategory, MatterKeyConsideration>,
  );

  const mandatoryConsiderations = MandatoryFlags.map<MatterKeyConsideration>(
    (category) => {
      const consideration = keyConsiderationsMap[category];

      if (consideration) {
        return consideration;
      }
      return {
        id: category,
        category,
        severity: 'Gray',
        matter: '',
        content: `No significant observations for "${category}"`,
      };
    },
  );

  const nonMandatoryConsiderations = Object.values(keyConsiderationsMap).filter(
    (consideration) => !MandatoryFlags.includes(consideration.category),
  );

  return {
    mandatoryConsiderations,
    nonMandatoryConsiderations,
  };
};
