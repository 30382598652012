import { FlagRounded } from '@mui/icons-material';
import { Button, Stack, styled } from '@mui/material';
import {
  MatterKeyConsiderationSeverities,
  MatterKeyConsiderationSeverity,
} from '../../../services/types/client-intake-types';
import theme from '../../../theme/theme';
import { getConsiderationCategoryColor } from '../../../utils/color';
import { HtmlTooltip } from '../../base/HtmlTooltip';

const FlagButton = styled(Button)({
  minWidth: '20px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.text.disabled,
  },
});

const Flags: React.FC<{
  currentSeverity: MatterKeyConsiderationSeverity;
  severities: MatterKeyConsiderationSeverity[];
  onSelected: (newSeverity: MatterKeyConsiderationSeverity) => void;
}> = ({ currentSeverity, severities, onSelected }) => {
  return (
    <Stack direction="column" spacing={1}>
      {severities.map((s) => (
        <FlagButton
          key={`severity-${s}`}
          onClick={() => onSelected(s)}
          disabled={s === currentSeverity}
        >
          <FlagRounded
            sx={{
              width: '20px',
              height: '20px',
              color: getConsiderationCategoryColor(s),
            }}
          />
        </FlagButton>
      ))}
    </Stack>
  );
};

export const KeyConsiderationFlag: React.FC<{
  severity: MatterKeyConsiderationSeverity;
  onChange: (newSeverity: MatterKeyConsiderationSeverity) => void;
}> = ({ severity, onChange }) => {
  return (
    <HtmlTooltip
      title={
        <Flags
          currentSeverity={severity}
          severities={MatterKeyConsiderationSeverities}
          onSelected={onChange}
        />
      }
    >
      <FlagRounded
        sx={{
          width: '20px',
          height: '20px',
          color: getConsiderationCategoryColor(severity),
        }}
      />
    </HtmlTooltip>
  );
};
