import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { usePatchMatterKeyConsiderationMutation } from '../../../services/api';
import {
  DetailedMatter,
  MatterKeyConsideration,
} from '../../../services/types/client-intake-types';
import { deepCompare } from '../../../utils/common';
import EditableTypography from '../../base/EditableTypography';
import { EditingIndicator } from '../../base/EditingIndicator';
import { useNotification } from '../../contexts/NotificationContext';
import { KeyConsiderationHeader } from './KeyConsiderationHeader';

export const KeyConsideration: React.FC<{
  selectedMatterData: DetailedMatter;
  consideration: MatterKeyConsideration;
}> = ({ selectedMatterData, consideration }) => {
  const [isHovered, setIsHovered] = useState(false);
  const notify = useNotification();
  const [updatedConsideration, setUpdatedConsideration] =
    useState(consideration);

  const [patchMatterKeyConsideration, { isError, isSuccess }] =
    usePatchMatterKeyConsiderationMutation();

  const handleChange = (changes: Partial<MatterKeyConsideration>) => {
    setUpdatedConsideration({ ...updatedConsideration, ...changes });
  };

  useEffect(() => {
    setUpdatedConsideration(consideration);
  }, [consideration]);

  const handleSave = useCallback(() => {
    if (deepCompare(updatedConsideration, consideration)) {
      return;
    }

    patchMatterKeyConsideration({
      matter_id: selectedMatterData.id,
      id: consideration.id,
      patch: updatedConsideration,
    });
  }, [
    selectedMatterData.id,
    updatedConsideration,
    consideration,
    patchMatterKeyConsideration,
  ]);

  useEffect(() => {
    if (updatedConsideration.severity !== consideration.severity) {
      handleSave();
    }
  }, [updatedConsideration.severity, consideration.severity, handleSave]);

  useEffect(() => {
    if (isError) {
      notify('Error updating key consideration', 'error', 3000);
    }
    if (isSuccess) {
      notify('Key consideration updated', 'success', 3000);
    }
  }, [isError, isSuccess, notify]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      key={consideration.id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={handleSave}
    >
      <EditingIndicator
        editing={!deepCompare(updatedConsideration, consideration)}
      >
        <KeyConsiderationHeader
          matterId={selectedMatterData.id}
          consideration={updatedConsideration}
          isHovered={isHovered}
          onChange={handleChange}
        />
        <EditableTypography
          multiline
          fullWidth
          variant="body2"
          color="info.light"
          value={updatedConsideration.content}
          onChange={(value) => handleChange({ content: value as string })}
        />
      </EditingIndicator>
    </Grid>
  );
};
