import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useMatterSelection from '../hooks/useMatterSelection';
import { usePatchMatterSummaryMutation } from '../services/api';
import {
  DetailedMatter,
  MatterSummary,
} from '../services/types/client-intake-types';
import theme from '../theme/theme';
import ClientScreenTile from './base/ClientScreenTile';
import EditableTypography from './base/EditableTypography';
import { EditingIndicator } from './base/EditingIndicator';
import { useNotification } from './contexts/NotificationContext';

const SummaryItem: React.FC<{
  title: string;
  children: React.ReactNode;
  isEditing: boolean;
}> = ({ title, children, isEditing }) => {
  return (
    <Grid item xs={12} sm={12} lg={4} key={title}>
      <EditingIndicator editing={isEditing}>
        <Typography variant="body1" sx={{ mb: '8px' }}>
          {title}
        </Typography>

        {children}
      </EditingIndicator>
    </Grid>
  );
};

const Summary: React.FC<{ matterId: string; summary: MatterSummary }> = ({
  matterId,
  summary,
}) => {
  const [content, setContent] = useState(summary.content);
  const notify = useNotification();
  const [patchMatterSummary, { isError, isSuccess }] =
    usePatchMatterSummaryMutation();

  useEffect(() => {
    if (isSuccess) {
      notify('Summary updated', 'success', 3000);
    }
  }, [isSuccess, notify]);

  useEffect(() => {
    if (isError) {
      notify('Failed to update summary', 'error', 3000);
    }
  }, [isError, notify]);

  const handleSave = () => {
    if (content === summary.content) {
      return;
    }
    patchMatterSummary({
      matter_id: matterId,
      id: summary.id,
      patch: { content },
    });
  };

  return (
    <SummaryItem
      title={summary.category}
      isEditing={content !== summary.content}
    >
      <EditableTypography
        variant="body2"
        color="info.light"
        multiline
        fullWidth
        value={content}
        onChange={(e) => {
          setContent(e as string);
        }}
        onBlur={handleSave}
      />
    </SummaryItem>
  );
};

const ActionItems: React.FC<{ matter: DetailedMatter | undefined }> = ({
  matter,
}) => {
  const actionItems = matter?.action_items.map((item) => item.content) ?? [];

  return (
    <SummaryItem title="Action Items" isEditing={false}>
      <List
        sx={{ listStyleType: 'disc', listStylePosition: 'inside', padding: 0 }}
      >
        {actionItems.length === 0 ? (
          <Typography variant="subtitle1">No action items</Typography>
        ) : (
          actionItems.map((item, index) => (
            <ListItem
              key={`${matter?.id}-${item}-${index}`}
              sx={{ padding: '8px', paddingTop: '0px', paddingY: 0 }}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="subtitle1" sx={{ display: 'list-item' }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))
        )}
      </List>
    </SummaryItem>
  );
};

const Summaries: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2} sx={{ margin: 'initial' }}>
        <Box
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.palette.border.main}`,
            paddingBottom: 3,
          }}
        >
          <Typography variant="h2">Case Summary</Typography>
        </Box>
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            {selectedMatterData?.summaries &&
              selectedMatterData.summaries.map((summary) => (
                <Summary
                  matterId={selectedMatterData.id}
                  key={summary.id}
                  summary={summary}
                />
              ))}
            <ActionItems matter={selectedMatterData} />
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default Summaries;
