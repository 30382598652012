export const EditingIndicator: React.FC<{
  editing: boolean;
  children: React.ReactNode;
}> = ({ editing, children }) => {
  const styles = {
    backgroundColor: editing ? 'aliceblue' : 'transparent',
    transition: 'all .5s ease',
    WebkitTransition: 'all .5s ease',
    MozTransition: 'all .5s ease',
  };

  return <div style={styles}>{children}</div>;
};
